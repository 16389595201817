@import '../../../assets/scss/tools';
@import './variables';

body,
html {
  min-width: $default-width;
  background: transparent;
  overflow: hidden;
}

html::before {
  width: $default-width;
  height: 100vh;
  position: absolute;
  background-color: $grey-dark-dk;
  content: '';
}
